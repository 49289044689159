import React from 'react'

export const TextSection = (props) => {

    return <div style={{display: 'flex', flex: 1, flexDirection: 'column', marginLeft: '10%', marginRight: '10%', width: '80%'}}>
            <h2 style={{color: '#8672c6', fontFamily: 'Thonburi', filter: 'drop-shadow(1mm 0 1mm rgb(25,25,25))'}}>{props.title}</h2>
            <p style={{fontSize: 16, color: '#bae0f8', fontFamily: 'Thonburi', filter: 'drop-shadow(1mm 0 1mm rgb(25,25,25))'}}>{props.body}</p>
    </div>
}

