import {
    backendUrl
} from './_BackendUrls';

class EmailVerificationService {

    verifyEmail(verificationToken) {
        return fetch(backendUrl + '/user/verify-email/' + verificationToken, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'PATCH',
            mode: 'cors',
        });
    }

}

export default new EmailVerificationService();
