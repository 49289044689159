import React from 'react';
import {HomeLogoContainer} from "../components/LogoContainer";
import {TextSection} from "../components/HomeView/TextSection";
import {useHistory, useLocation} from "react-router-dom";

export const PrivacyPolicyView = () => {

    return <div style={{   flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        paddingBottom: '5%', height: '100%'}}>
        <HomeLogoContainer/>

        <div
            style={{
                display: 'flex', flex: 1, flexDirection: 'column', marginLeft: '10%', marginRight: '10%'
            }}>
        <div style={{flex: 1, color: '#9ac1d6', fontFamily: 'Thonburi', filter: 'drop-shadow(1mm 0 1mm rgb(25,25,25))'}}>
        <h1 style={{fontWeight: 'bold', color: '#8672c6', fontFamily: 'Thonburi', filter: 'drop-shadow(1mm 0 1mm rgb(25,25,25))'}}>Privacy Policy</h1>

        <p>One of our main priorities is the privacy and security of our community. This Privacy Policy document outlines the type of data collected on the members of the community, and how it is used.  Within
        this document, the term "The App" is used to refer to Pro-Social's iOS app, as well as the associated websites are services.</p>

        <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>

        <h2 style={{color: '#8672c6', fontFamily: 'Thonburi', filter: 'drop-shadow(1mm 0 1mm rgb(25,25,25))'}}>Summary</h2>
        <p>This section is provided as an easily-digestible summary of The App's privacy policy, but is not a substitute for the remainder of the policies outlined within this document.</p>
        <p>
            Information collected on users is only used in two ways:
            <ol>
                <li>To facilitate the use of The App.  Emails are used to allow users to log in to the app, and reset their passwords.</li>
                <li>To help identify and remove scammers, sellers, advertisers, and other fraudulent or malicious users</li>
            </ol>
            No user information is sold or distributed for advertising purposes to third-parties.  No additional information is collected
            on users other than what they themselves enter into the App, other than the user's device ID.  Device IDs are collected upon registration, and are only used to prevent malicious users who have
            been removed from the community from simply creating a new account.
        </p>

        <h2 style={{color: '#8672c6', fontFamily: 'Thonburi', filter: 'drop-shadow(1mm 0 1mm rgb(25,25,25))'}}>Consent</h2>

        <p>By using The App, community members consent to The App's privacy policy and terms of service.  Any user found to be degrading the quality of the community by
        undertaking behavior which is deemed inappropriate or undesirable may be removed temporarily or permanently.</p>

        <h2 style={{color: '#8672c6', fontFamily: 'Thonburi', filter: 'drop-shadow(1mm 0 1mm rgb(25,25,25))'}}>Information we collect</h2>

        <p>When you register for an Account, we may ask for your contact information, including email address.  This data is used internally to allow users to access The App, as well as by users to recover their account.</p>
        <p>If you contact us directly, we may receive additional information about you such as your name, email address, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
        <p>Users that are suspected of being scammers, sellers, advertisers, or otherwise fraudulent or malicious, may have information collected pursuant to our ability to moderate the community.  This includes user-generated content which has been reported by other users.
        Ownership of such information can be retained by The App, and depending on the severity or necessity, transmitted to appropriate law enforcement entities.  To facilitate the safe operation of The App and the community, user information may be collected and retained, with the ownership of the information
        being maintained by The App for the purposes of community safety, normal operations, or any other reason other than the sale or transmission of user data to third-parties
        for the purpose of advertising to users.
        </p>

        <h2 style={{color: '#8672c6', fontFamily: 'Thonburi', filter: 'drop-shadow(1mm 0 1mm rgb(25,25,25))'}}>How we use your information</h2>

        <p>We use the information we collect in various ways, including to:</p>

        <ul>
            <li>Provide, operate, and maintain The App</li>
            <li>Improve, personalize, and expand The App</li>
            <li>Understand and analyze how you use The App</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>Communicate with you to provide you with updates and other information relating to The App.</li>
            <li>Send you emails that you specifically request, such as registration and password reset emails</li>
            <li>Find and prevent fraud</li>
        </ul>

        <h2 style={{color: '#8672c6', fontFamily: 'Thonburi', filter: 'drop-shadow(1mm 0 1mm rgb(25,25,25))'}}>Advertising Partners Privacy Policies</h2>

        <p>The App has no advertising partners other than those used for acquiring new users.  No user information is transmitted to third-parties for the
        purpose of advertising.</p>

        <h2 style={{color: '#8672c6', fontFamily: 'Thonburi', filter: 'drop-shadow(1mm 0 1mm rgb(25,25,25))'}}>CCPA Privacy Rights</h2>

        <p>Under the CCPA, among other rights, California consumers have the right to:</p>
            <ul>
                <li>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</li>
                <li>Request that a business delete any personal data about the consumer that a business has collected.</li>
                <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</li>
            </ul>
        <p>While this privacy policy clearly outlines that only information that is directly supplied to The App by users is collected, and that no user information is
            sold or transmitted to third-parties for the purpose of advertising,
            The App is obligated to respond to any requests made by users who wish to exercise the rights protected by the CCPA.  While the App's privacy policy outlines the many ways which The App voluntarily
            affords the rights protected by the CCPA, users wishing to explicitly exercise any of these rights are asked to contact us.</p>

        <h2 style={{color: '#8672c6', fontFamily: 'Thonburi', filter: 'drop-shadow(1mm 0 1mm rgb(25,25,25))'}}>GDPR Data Protection Rights</h2>

        <p>Under the GDPR, among other rights, European Union consumers have the right to:</p>
            <ul>
                <li>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</li>
                <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</li>
                <li>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
                <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
                <li>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</li>
                <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
            </ul>
        <p>While this privacy policy clearly outlines that only information that is directly supplied to The App by users is collected, and that no user information is
            sold or transmitted to third-parties for the purpose of advertising,
            The App is obligated to respond to any requests made by users who wish to exercise the rights protected by the GDPR.  While the App's privacy policy outlines the many ways which The App voluntarily
            affords the rights protected by the GDPR, users wishing to explicitly exercise any of these rights are asked to contact us.</p>

        <h2 style={{color: '#8672c6', fontFamily: 'Thonburi', filter: 'drop-shadow(1mm 0 1mm rgb(25,25,25))'}}>Children's Information</h2>

        <p style={{paddingBottom: '5%'}}>Another priority is protecting children.  The App is limited to users age 17+, and people below that age
            are not allowed to become users.
            The App does not knowingly collect any Personal Identifiable Information from children under the age of 17. If you think that your child provided any
            kind of information to The App, we strongly encourage you to contact us immediately and we will do our best to promptly remove such
            information from our records.  Also, if you suspect that another user is under the minimum age requirement, please contact us so that we may investigate the claim
            and remove them from the community if the claim is substantiated</p>
        </div>
        </div>
    </div>
}

