import React, { Component } from 'react';
import './App.css';
import ProSocialWebsite from './pages/ProSocialWebsite'

class App extends Component {
  render() {
    return (
      <div style={{ height: '100%'}}>
        <ProSocialWebsite/>
      </div>
    );
  }
}

export default App;
