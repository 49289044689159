import React, {useState} from "react"
import {LoginOutlined} from '@ant-design/icons';
import UserService from "../service/UserService";
import {errorNotification, successNotification} from '../components/Notification'
import {Input, Button} from 'antd';
import {useHistory} from "react-router-dom";
import EmailVerificationService from "../service/EmailVerificationService";

const inputFieldStyle = {marginBottom: '10px', width: '20em', borderRadius: 20, backgroundColor: '#27393b', border: '3px solid #6a989c', color: '#6a989c'};

export const PasswordResetForm = (props) => {

    let [newPassword1, setNewPassword1] = useState(() => '');
    let [newPassword2, setNewPassword2] = useState(() => '');
    let [showPasswordDidNotMatchMessage, setShowPasswordsDidNotMatchMessage] = useState(() => false);
    let [showPasswordTooShortMessage, setShowPasswordTooShortMessage] = useState(() => false);
    let [showPasswordResetUnsuccessfulMessage, setShowPasswordResetUnsuccessfulMessage] = useState(() => false);
    const {passwordResetToken} = props;

    const submitClicked = () => {

        if (newPassword1 !== newPassword2) {
            setShowPasswordsDidNotMatchMessage(true);
            setNewPassword1('');
            setNewPassword2('');
        }
        else if (newPassword1.length < 7) {
            setShowPasswordTooShortMessage(true);
            setNewPassword1('');
            setNewPassword2('');
        }
        else {
            setShowPasswordsDidNotMatchMessage(false);
            setShowPasswordTooShortMessage(false);
            UserService.resetPassword(passwordResetToken, newPassword1)
                .then((response) => {
                    if(response.ok){
                        props.setPasswordResetSuccessful(true);
                    } else if (response.status === 400){
                        setShowPasswordResetUnsuccessfulMessage(true);
                    }
                })
        }
    }

    const PasswordsDidNotMatchMessage = () =>{
        if(showPasswordDidNotMatchMessage === true){
            return <div style={{flex: 1}}>
                <p style={{color:'#e06e82', fontSize: '1.25em', fontFamily: 'thonburi'}}>Your passwords did not match</p>
            </div>
        }
        else if(showPasswordTooShortMessage === true){
            return <div style={{flex: 1}}>
                <p style={{color:'#e06e82', fontSize: '1.25em', fontFamily: 'thonburi'}}>Please choose a password longer than 6 characters</p>
            </div>
        }
        else if(showPasswordResetUnsuccessfulMessage === true){
            return <div style={{flex: 1}}>
                <p style={{color:'#e06e82', fontSize: '1.25em', fontFamily: 'thonburi'}}>Request a new password reset email</p>
            </div>
        }
        else{
            return null;
        }
    }
    return (
        <div
            style={{
                margin: '3%',
            }}>
            <div style={{width: '100%'}}>
                <Input type="password"
                       autoFocus
                       name="newPassword1"
                       placeholder="new password"
                       size="large"
                       value={newPassword1}
                       onChange={e => setNewPassword1(e.target.value)}
                       style={inputFieldStyle}
                       onPressEnter={submitClicked}
                />
            </div>
            <div style={{width: '100%'}}>
                <Input type="password"
                       name="newPassword2"
                       placeholder="and again"
                       size="large"
                       value={newPassword2}
                       onChange={e => setNewPassword2(e.target.value)}
                       style={inputFieldStyle}
                       onPressEnter={submitClicked}
                />
            </div>
            <PasswordsDidNotMatchMessage/>
            <div id='PasswordResetSubmitButton'
            onClick={() => {
                submitClicked()}}>
            <p style={{fontSize: '1.5em', alignSelf: 'center', margin: '0 auto', fontFamily: 'Thonburi'}}>Reset Password</p>
        </div>
        </div>
    );
}
