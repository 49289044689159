import React from 'react'
import Logo from './../img/Pro-SocialLogo.png';

export const HomeLogoContainer = () =>
    <div style={{display: 'flex', flex: 1, width: '100%', justifyContent: 'center', paddingBottom: '5%'}}>
        <img src={Logo} alt="Logo" style={{margin:'2.5em', marginBottom:'1em', width: '50%', resizeMode: 'cover',
            filter: 'drop-shadow(1mm -1mm 1mm rgb(25,25,25))'}}/>
    </div>

export const NewHomeLogoContainer = () =>
    <div style={{display: 'flex', flex: 1, width: '100%', justifyContent: 'center'}}>
        <img src={Logo} alt="Logo" style={{margin:'2.5em', marginBottom:'1em', width: '50%', resizeMode: 'cover',
            filter: 'drop-shadow(1mm -1mm 1mm rgb(25,25,25))'}}/>
    </div>

export const VerificationLogoContainer = () =>
    <div style={{display: 'flex', justifyContent: 'center', width: '100%',  marginTop: '5%', flex: 1}}>
        <img src={Logo} alt="Logo" style={{display: 'flex' ,width: '50%', objectFit: 'contain',
            filter: 'drop-shadow(1mm -1mm 1mm rgb(25,25,25))'}}/>
    </div>

