import React from 'react';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import PasswordResetView from './PasswordResetView'
import {HomeView} from "./HomeView";
import {EmailVerificationView} from "./EmailVerificationView";
import {PrivacyPolicyView} from "./PrivacyPolicyView";
import {BetaTestingView} from "./BetaTestingView";
import {EulaView} from "./EulaView";

const ProSocialWebsite = () => {

    return (
        <div style={{
            backgroundColor: 'rgb(56,82,84)',
            background: 'radial-gradient(ellipse at top, #385354, #291C4A)',
            position: 'absolute',
            top: '0',
            right: '0',
            bottom: '0',
            left: '0',
            overflow: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex'
        }}>
            <BrowserRouter>
                    <Switch>
                        <Route path="/password-reset/:passwordResetToken/" component={PasswordResetView}/>
                        <Route path="/verification/:verificationToken" component={EmailVerificationView}/>
                        {/*<Route path="/end-user-license-agreement" component={EulaView}/>*/}
                        <Route path="/privacy-policy" component={PrivacyPolicyView}/>
                        {/*<Route path="/beta-testing" component={BetaTestingView}/>*/}
                        <Redirect exact from="/index.html" to="/"/>
                        <Route path="/" exact component={HomeView}/>
                    </Switch>
            </BrowserRouter>
        </div>
    )
}

export default ProSocialWebsite
