import React from 'react';
import {HomeLogoContainer, NewHomeLogoContainer} from "../components/LogoContainer";
import {TextSection} from "../components/HomeView/TextSection";
import {useHistory, useLocation} from "react-router-dom";
import {websiteUrl} from "./../service/_BackendUrls"
import DownloadOnAppStoreImage from './../img/whiteDownloadOnAppStore.svg';

export const HomeView = () => {

    let queryParams = new URLSearchParams(useLocation().search)
    let history = useHistory();
    let passwordResetToken = queryParams.get('prt');
    if (passwordResetToken !== null) {
        history.push({
            pathname: `/password-reset/` + passwordResetToken
        });
    }

    return <div
        style={{
            flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            paddingBottom: '10%', height: '100%'
        }}>
        {/*Apple Smart Banner*/}
        {/*<MetaTags>*/}
        {/*    <meta name="apple-itunes-app" content="app-id=1622260145"/>*/}
        {/*</MetaTags>*/}

        <NewHomeLogoContainer/>
        <div style={{display: 'flex', justifyContent:'center', alignItems:'center', marginBottom: '10%'}}>
            <a href="https://apps.apple.com/us/app/pro-social/id1622260145?itsct=apps_box_link&itscg=30200" target="_blank" rel="noreferrer">
                <img
                    src={DownloadOnAppStoreImage}
                    alt="example"
                />
            </a>
        </div>
        <TextSection
            title={"What is Pro-Social?"}
            body={"Pro-Social is a community of people committed to normalizing making new friends online.  My app facilitates non-superficial social interactions between " +
                "real people by providing every single person with the ability to decide exactly the type of people that they are interested in meeting, with no features hidden behind " +
                "paywalls.  "}
        />
        <TextSection
            title={"Who is it for?"}
            body={"You.  Everyone at some point has wondered how to make friends or meet new people.  It can be especially difficult " +
                "when you are away from home, or just moved somewhere unfamiliar.  If you want to find someone to talk to about your favorite TV show, your " +
                "hobbies, or even the place you just moved from, I'll help you meet them."}
        />
        <TextSection
            title={"How does it work?"}
            body={"All you need to do is press a single button to meet someone new.  Behind the scenes, the process for selecting the people that you are most likely interested in talking to is quite complex.  First, the app only shows you " +
                "people who fall within the gender and age-range that you have selected.  From there, it analyzes the keywords you use to described yourself and " +
                "matches you with people who have similar interests.  I am constantly improving the app's ability to find the best connections possible, and with 600+ ways to describe yourself," +
                " I am able to use what really matters to you in order to help you find someone interesting."}
        />
        <TextSection
            title={"What makes it different?"}
            body={"There are many photo-centric dating apps that provide validation slot machines, but for the vast majority of people they rarely lead " +
                "to rewarding interactions. Also, chat apps that randomly match people, or apps that prevent people from selecting age and gender preferences without paying, leave the quality of matches to chance. " +
                "The app uses keyword-based matches along with your gender preference and age preference to enable it to not only provide " +
                "you with the best matches possible, but allow others to see some things about you when you first meet.  Keywords are a valuable " +
                "and convenient way to start a conversation with someone new!"}
        />
        <TextSection
            title={"What makes it safe?"}
            body={"The app has a unique system that requires you to provide consent before someone can send you any images," +
                " so receiving unsolicited images is impossible.  I have also implemented a set of community standards " +
                "that are much more strict than any other app in existence.  The proprietary system " +
                " that was created to enforce these guidelines can temporarily or permanently ban people who engage in " +
                " unwelcome behavior. While my app was designed to welcome everyone, I do not tolerate people that behave" +
                " in a manner that is unappreciated by others. " +
                "By analyzing user-submitted reports, the app can identify people that consistently demonstrate a desire to" +
                " misbehave, then promptly and permanently remove them from the community." +
                ""}
        />

        <TextSection
            title={"What makes it secure?"}
            body={"All data sent to and from the app's servers automatically uses HTTPS, a secure and encrypted way " +
                "to transmit information over the internet that eliminates the possibility of anyone else intercepting the messages and reading them. To facilitate " +
                " the bi-directional, real-time communication that needs to occur for a chat, the app uses a WebSocket protocol that automatically submits an encrypted token during connection which is generated " +
                "when you log in. Only my backend service is able to decrypt and verify this token, and it uses the token to ensure not only that the message " +
                "was sent by a person with a valid account, but that the person isn't trying to pretend to be someone else."}
        />

        {/*this section includes links so it had to be pulled out of the component*/}
        <div style={{flexDirection: 'column', marginLeft: '10%', marginRight: '10%', width: '80%'}}>
            <h2 style={{color: '#8672c6', fontFamily: 'Thonburi', filter: 'drop-shadow(1mm 0 1mm rgb(25,25,25))'}}>Why
                was it made?</h2>
            <p style={{
                fontSize: 16,
                color: '#bae0f8',
                fontFamily: 'Thonburi',
                filter: 'drop-shadow(1mm 0 1mm rgb(25,25,25))'
            }}>Meeting new people can be hard, especially if you are in the middle of a global pandemic. Self-reported
                rates of "frequent to constant" feelings of
                loneliness <a
                    href="https://news.harvard.edu/gazette/story/2021/02/young-adults-teens-loneliness-mental-health-coronavirus-covid-pandemic/#:~:text=In%20the%20recently%20released%20results,compared%20with%2025%20percent%20who"
                    target='_blank' style={{color: '#bae0f8', fontFamily: 'Thonburi', textDecorationLine: 'underline'}}>rose
                    to 36%</a>{' '}
                during that time. Even now that the world has returned to normal, many of the traditional methods of
                meeting new people, like bars
                or social clubs, can be both time-consuming and limited in the variety and type of people that attend.
                In 2022,
                {' '}<a href="https://www.itu.int/en/ITU-D/Statistics/Pages/facts/default.aspx" target='_blank' style={{
                    color: '#bae0f8',
                    fontStyle: 15,
                    fontFamily: 'Thonburi',
                    textDecorationLine: 'underline'
                }}>5.3 billion people</a>{' '}
                used the internet. The world has
                never been more connected. Unfortunately, there were no options for meeting people via the internet that
                weren't specifically geared towards dating or
                filled with spammers, scammers, and unsolicited images. It was clear that I could create something
                better. After more than two full years of development, my app was ready to be released to the world.</p>
        </div>
        <div style={{flexDirection: 'column', marginLeft: '10%', marginRight: '10%', width: '80%'}}>
            <h2 style={{
                color: '#8672c6',
                fontFamily: 'Thonburi',
                filter: 'drop-shadow(1mm 0 1mm rgb(25,25,25))'
            }}>Contact Me</h2>
            <p style={{
                fontSize: 16,
                color: '#bae0f8',
                fontFamily: 'Thonburi',
                filter: 'drop-shadow(1mm 0 1mm rgb(25,25,25))'
            }}>
                The best way to contact me is through the "Message the Creator" button that can be found within the app
                at the
                bottom of your About Me screen . If you would like
                to send me an email, you can send it to
                {' '}<a href="mailto:ProSocialQuestions@gmail.com" target='_blank' style={{
                color: '#bae0f8',
                fontStyle: 15,
                fontFamily: 'Thonburi',
                textDecorationLine: 'underline'
            }}>ProSocialQuestions@gmail.com</a>{' '}
            </p>
        </div>
        <div
            style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                marginTop: '20%',
            }}>
            <a href={websiteUrl + '/privacy-policy'} target='_blank'
               style={{
                   color: '#bae0f8',
                   fontStyle: 15,
                   fontFamily: 'Thonburi',
                   textDecorationLine: 'underline',
                   marginRight: '2.5%'
               }}>Privacy
                Policy</a>
            <a href={'https://www.apple.com/legal/internet-services/itunes/dev/stdeula/'} target='_blank' style={{
                color: '#bae0f8',
                fontStyle: 15,
                fontFamily: 'Thonburi',
                textDecorationLine: 'underline',
                marginLeft: '2.5%'
            }}>End User
                License Agreement</a>
        </div>
        <div style={{height: 100}}>
        </div>
    </div>
}

