import React, {Component, useState} from 'react'
import {PasswordResetForm} from '../forms/PasswordResetForm';
import {HomeLogoContainer, LogoContainer} from "../components/LogoContainer";
import {FormContainer} from "../forms/FormContainer";

export const PasswordResetView = (props) => {

    const [passwordResetToken, setPasswordResetToken] = useState(() => props.match.params.passwordResetToken);

    const [passwordResetSuccessful, setPasswordResetSuccessful ] = useState(() => null)


    const BodyComponent = () => {
        if(passwordResetSuccessful === null){
            return <PasswordResetForm
                passwordResetToken={passwordResetToken}
                setPasswordResetSuccessful={setPasswordResetSuccessful}
            />

        }
        else if(passwordResetSuccessful === true){
            //return error view
            return <div id='EmailVerificationText'>
                <p>
                    You password has been reset!<br/>You can now return to the App to log in.
                </p>
            </div>
        }
        else if(passwordResetSuccessful === false){
            return <div id='EmailVerificationText' style={{color:'#f1a2a2'}}>
                <p>
                    Your password could not be reset.  Revisit the link you received in your email to try again.
                </p>
            </div>
        }
    }

    return (
        <FormContainer>
            <HomeLogoContainer/>
            <BodyComponent/>
        </FormContainer>
    )
}

export default PasswordResetView
