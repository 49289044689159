import React from 'react';
import '../../forms/css/FormStyles.css'
import EmailVerificationService from "../../service/EmailVerificationService";

export const EmailVerificationButton = (props) => {
    return <div id='EmailVerificationButton'
                onClick={() => {
                    //send email verification request
                    EmailVerificationService.verifyEmail(props.verificationToken)
                        //if successful
                        .then(response => {
                                if (response.ok) {
                                    //redirect to welcome view
                                    props.setEmailVerificationSuccessful(true);
                                } else {
                                    //redirect to error view
                                    props.setEmailVerificationSuccessful(false);
                                }
                            }
                        )

                }
                }>
        <p style={{fontSize: '1.5em', alignSelf: 'center', margin: '0 auto', fontFamily: 'Thonburi', color: '#5f2181'}}>Activate Account</p>
    </div>
}
