const development = false;

const getBackendUrl = () => {
    const surveyProductionBackendUrl = 'https://pro-social-service.mjfre.com'
    const surveyDevelopmentBackendUrl = 'http://localhost:8080'
    if (development) {
        return surveyDevelopmentBackendUrl
    } else {
        return surveyProductionBackendUrl
    }
}

const getWebsiteUrl = () => {
    const productionWebsiteUrl = 'https://pro-social.mjfre.com'
    const developmentWebsiteUrl = 'http://localhost:3000'
    if (development) {
        return developmentWebsiteUrl
    } else {
        return productionWebsiteUrl
    }
}

const backendUrl = getBackendUrl()
const websiteUrl = getWebsiteUrl()

export { backendUrl, websiteUrl }
