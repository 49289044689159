import React, {useState} from 'react'
import {HomeLogoContainer, VerificationLogoContainer} from "../components/LogoContainer";
import {FormContainer} from "../forms/FormContainer";
import '../forms/css/FormStyles.css'
import {EmailVerificationButton} from "../components/EmailVerification/EmailVerificationButton";
import {websiteUrl} from "../service/_BackendUrls";

export const EmailVerificationView = (props) => {

    const {verificationToken} = props.match.params;

    // const [emailVerificationSuccessful, setEmailVerificationSuccessful] = useState(() => null)
    const [emailVerificationSuccessful, setEmailVerificationSuccessful] = useState(() => null)

    const BodyComponent = () => {
        if (emailVerificationSuccessful === null) {
            return <div style={{alignItems: 'center', justifyContent: 'center'}}>
                <p style={{color:'#7ea0b2',fontFamily: 'Thonburi', marginLeft: '10%', marginRight: '10%'}}>If you are really bored, you can read the
                    <a href={'https://www.apple.com/legal/internet-services/itunes/dev/stdeula/'} target='_blank' style={{
                        color: '#bae0f8',
                        fontStyle: 15,
                        fontFamily: 'Thonburi',
                        textDecorationLine: 'underline',
                        marginLeft: '1%',
                        marginRight: '1%'
                    }}>End User
                        License Agreement</a>
                    and
                    <a href={websiteUrl + '/privacy-policy'} target='_blank'
                                                                style={{
                                                                    color: '#bae0f8',
                                                                    fontStyle: 15,
                                                                    fontFamily: 'Thonburi',
                                                                    textDecorationLine: 'underline',
                                                                    marginLeft: '1%',
                                                                    marginRight: '1%'
                                                                }}>Privacy
                    Policy</a>
                   </p>
                <EmailVerificationButton
                verificationToken={verificationToken}
                setEmailVerificationSuccessful={setEmailVerificationSuccessful}
            />
            </div>
        } else if (emailVerificationSuccessful === true) {
            //return error view
            return <div id='EmailVerificationSuccessfulText'>
                <p>
                    Verified!<br/>Now get back to the App.
                </p>
            </div>
        } else if (emailVerificationSuccessful === false) {
            return <div id='EmailVerificationFailedText' style={{color: '#f1a2a2'}}>
                <p>
                    Email could not be verified. Revisit the link you received in your email to try again.
                </p>
            </div>
        }
    }
    return (
        <FormContainer>
            <div style={{marginBottom: '5%'}}>
            <VerificationLogoContainer/>
            </div>
            <BodyComponent/>
        </FormContainer>
    )

}
